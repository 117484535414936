:root {
  --target-marquee-width: 275px;
  --max-marquee-height: 2em;
  --background-color: #000000;
  --foreground-color: #f10202;
  --line-color: #a09e9d;
  --accent-color: #fabc04;

  /* Colors from BTTA logo */
  --white: #f4f4f4;
  --light-gray: #adaba9;
  --gray: #5b534a;
  --black: #000000;
  --red: #f14402;
  --yellow: #fabc04;
}

@font-face {
  font-family: "bitwise";
  src: url("fonts/bitwise.eot"); /* IE9 Compat Modes */
  src: /* IE6-IE8 */ url("fonts/bitwise.eot?#iefix") format("embedded-opentype"),
    /* Super Modern Browsers */ url("fonts/bitwise.woff2") format("woff2"),
    /* Pretty Modern Browsers */ url("fonts/bitwise.woff") format("woff"),
    /* Safari, Android, iOS */ url("fonts/bitwise.ttf") format("truetype"),
    /* Legacy iOS */ url("fonts/bitwise.svg#svgFontName") format("svg");
}

body {
  font-family: "bitwise", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background-color);
  color: var(--foreground-color);

  font-size: 20pt;
}

/*
 * We need to mirror the flex settings for the App container so the hidden form
 * looks better.
 */
form {
  display: flex;
  flex-direction: column;
}

header {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 1em;
}

header img {
  vertical-align: middle;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: 450px;
}

footer {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

footer img {
  vertical-align: middle;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: 60%;
}

.App {
  width: 1000px;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.scoresContainer {
  flex: 1 1 auto;
}

.scores {
  width: 100%;
  display: grid;
  grid-template-columns: 0fr 1fr 0fr;
  column-gap: 1em;
  /* background-color: #ff00ff; */
  height: 100%;

  font-size: 35pt;
}

/*
 * Make each item a flexbox container so we can properly position
 * and align things.
 */
.scores .gameName,
.scores .playerName,
.scores .score {
  display: flex;
}

/*
 * We insert hr elements rather than an underline because you can't really
 * underline css grid items. Because of this we need to force the hr elements to
 * be the full width of the grid.
 */
.scores .line {
  grid-column: 1 / 4;
  width: 100%;
  margin: 0px;

  display: block;
  border: none;
  color: white;
  height: 1px;
  background: var(--line-color);
  background: radial-gradient(
    var(--line-color) 0%,
    var(--background-color) 75%
  );
}

.scores {
  color: var(--foreground-color);
}

.scores .newScore {
  color: var(--accent-color);
}

.scores .gameName {
  height: var(--max-marquee-height);
  width: var(--target-marquee-width);

  align-items: center;
  align-self: center;
}

.scores .gameName img {
  max-height: var(--max-marquee-height);
  width: var(--target-marquee-width);
  height: auto;
  display: block;
}

.scores .playerName {
  justify-self: center;
  align-self: center;
}
.scores .score {
  justify-self: right;
  align-self: center;
}

.imageContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #000000;
  width: 100%;
  height: 100%;
}

.fullscreenImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* Size limit */
  max-width: 100%;
  max-height: 100%;

  /* Other required settings */
  margin: auto;
  overflow: auto;
}

.playPause {
  position: absolute;
  bottom: 0;
  right: 0;
}

.fadeOut {
  animation: fadeOut 3s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0%   { opacity: 1; }
  33%  { opacity: 1; }
  100% { opacity: 0; }
}

/*
 * Overrides for small screens
 */
@media (max-width: 1000px) {
  header img {
    width: 90%;
  }

  .App {
    width: 100%;
  }

  .scores {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .scores .line {
    grid-column: 1;
  }

  .scores .gameName,
  .scores .playerName,
  .scores .score {
    justify-self: center;
    align-self: center;
  }

  .scores .gameName {
    height: inherit;
    width: 100%;
  }

  .scores .gameName img {
    margin: auto;
    width: 90%;
    max-height: 500px;
  }

  .imageContainer {
    display: none;
  }
}
